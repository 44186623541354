import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './index.scss';

import { Column, Text, Image, Link, BlogMeta } from 'components/atoms';

const formatDate = dateString => {
  const date = new Date(dateString);
  return [
    date.toLocaleString('default', { month: 'long' }),
    date.getFullYear(),
  ].join(' ');
};

const formatCategories = categories => {
  return (categories || []).map(c => c.name).join(', ');
};

const FeaturedBlogItem = ({ data, className }) => {
  const { categories, date, featured_media, path, title } = data || {};
  const { source_url, alt_text, acf, media_details, title: image_title } =
    featured_media || {};

  const [isHover, setIsHover] = useState(false);

  return (
    <Column className={cn('featured-blog__item', [className])} lg="4">
      <Link href={path}>
        <Image
          className={cn(
            'featured-blog__item-image',
            isHover && 'featured-blog__item-image_hover'
          )}
          src={source_url}
          alt={alt_text}
          title={image_title}
          placeholder64={acf?.placeholder_base64}
          lazyLoading
          imageFit="cover"
          imageAspect={446 / 275}
          image={media_details}
        />
      </Link>
      <BlogMeta>
        <Text tag="span" className="subheader subheader_blog">
          {formatDate(date)}
        </Text>
        <Text tag="span" className="subheader subheader_blog ">
          {formatCategories(categories)}
        </Text>
      </BlogMeta>

      <Link
        href={path}
        isRouter={false}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        <Text tag="h4" className="">
          {title}
        </Text>
      </Link>
    </Column>
  );
};

FeaturedBlogItem.defaultProps = {
  className: '',
};

FeaturedBlogItem.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default FeaturedBlogItem;
