import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './index.scss';

import { Section, Container, Row, Column, Text, Link } from 'components/atoms';
import OurServicesCard from 'components/molecules/OurServicesCard';
import IndustriesCard from 'components/molecules/IndustriesCard';
import FeaturedBlogItem from 'components/molecules/FeaturedBlogItem';
import WorksList from 'components/organisms/WorksList';

import { useAllPost } from 'graphql/hooks';

const MainpageContent = ({ data, blog, className, tagsData }) => {
  const { id, caption, link, case_studies, works, services, industries } =
    data || {};
  const { title, description } = caption || {};
  const { path, text } = link || {};

  const allPosts = useAllPost();

  let content = null;
  if (case_studies || works) {
    content = (
      <WorksList
        homepage={
          case_studies ? case_studies.concat(works) : works.concat(case_studies)
        }
      />
    );
  } else if (services) {
    content = (
      <Column col="12">
        <Row>
          {services.map((cardData, id) => (
            <OurServicesCard data={cardData} tagsData={tagsData} key={id} />
          ))}
        </Row>
      </Column>
    );
  } else if (industries) {
    const industriesCards =
      industries &&
      industries.map((item, index) => {
        return <IndustriesCard data={item} key={index} />;
      });
    content = (
      <Column xl="9" lg="11" col="12" className={cn('offset-lg-1')}>
        <div className="mainpage-content__industries">{industriesCards}</div>
      </Column>
    );
  } else if (blog) {
    content = (
      <Column col="12">
        <Row>
          {allPosts.slice(0, 3).map((post, id) => (
            <FeaturedBlogItem data={post.node} key={id} />
          ))}
        </Row>
      </Column>
    );
  }

  const ContentContainer = props =>
    case_studies || works ? (
      <>{props.children}</>
    ) : (
      <Container>
        <Row>{props.children}</Row>
      </Container>
    );

  return (
    <Section
      className={cn('mainpage-content', [className])}
      id={data.css_id || id}
    >
      <Container>
        <Row>
          <Column col="12" lg="5" className="offset-lg-1">
            <div
              className={cn(
                'mainpage-content__header',
                link && 'mainpage-content__header--with-link'
              )}
            >
              {title && (
                <div
                  className="mainpage-content__title"
                  dangerouslySetInnerHTML={{ __html: title }}
                ></div>
              )}
              {description && (
                <div
                  className="mainpage-content__description"
                  dangerouslySetInnerHTML={{ __html: description }}
                ></div>
              )}
              {link && (
                <Link href={path} className="mainpage-content__link">
                  <Text className="body-sm body-sm_bold">{text}</Text>
                </Link>
              )}
            </div>
          </Column>
        </Row>
      </Container>
      <ContentContainer>{content}</ContentContainer>
    </Section>
  );
};

MainpageContent.defaultProps = {
  className: '',
};

MainpageContent.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string,
  tagsData: PropTypes.array,
};

export default MainpageContent;
