import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import './index.scss';
import { Image, Link, Text, Video, Column } from 'components/atoms';

const OurServicesCard = ({ data, tagsData }) => {
  const { title, description, tags, image } = data || {};
  const { source_url, alt_text, title: image_title, media_details } =
    image || {};
  const isVideoPoster = false;
  const videoSrc = '';
  const tagsInfo = tagsData.map(edges => edges.node);

  let isVideoBackground = isVideoPoster && window.innerWidth > 1024;

  return (
    <Column lg="4" className={cn('our-services-card')}>
      <div className="our-services-card__wrapper">
        <div
          className={cn('our-services-card__poster', {
            'our-services-card__poster-video-wrap': isVideoBackground,
          })}
        >
          {!isVideoBackground ? (
            <Image
              className="our-services-card__poster-img"
              src={source_url}
              imageFit={'fill'}
              imageAspect={260 / 284}
              lazyLoading
              alt={alt_text}
              title={image_title}
              image={media_details}
            />
          ) : (
            <Video
              className="our-services-card__poster-video"
              controls={false}
              autoPlay={true}
              loop={true}
              muted={true}
              src={videoSrc}
            />
          )}
        </div>
        <div className="our-services-card__title">
          <Text tag="h3">{title}</Text>
        </div>
        <div className="our-services-card__text-block">
          <Text type="small" color="dark-purple">
            {description}
          </Text>
        </div>
        <div className="our-services-card__links-block">
          {tags.map(({ term_id }, id) => {
            const tag = tagsInfo.find(
              ({ wordpress_id }) => wordpress_id === term_id
            );
            const { path, name } = tag;
            const isLink = path === null;

            const textOrLink = isLink ? (
              <Text
                tag="p"
                className="type_medium color_dark-purple body-sm_bold"
                key={id}
              >
                {name}
              </Text>
            ) : (
              <Link
                className="our-services-card__link-item"
                key={id}
                href={path}
              >
                <Text
                  tag="p"
                  className="type_medium color_dark-purple body-sm_bold"
                >
                  {name}
                </Text>
              </Link>
            );

            return textOrLink;
          })}
        </div>
      </div>
    </Column>
  );
};

OurServicesCard.defaultProps = {
  className: '',
};

OurServicesCard.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default OurServicesCard;
